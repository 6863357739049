export const addDepartment =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "ADD_DEPARTMENT_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const setDepartments = firebase
        .functions()
        .httpsCallable("setDepartments");
      const result = await setDepartments(data);
      if (result.data.success) {
        dispatch({
          type: "ADD_DEPARTMENT_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "ADD_DEPARTMENT_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "ADD_DEPARTMENT_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const getDepartments =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "GET_DEPARTMENT_LOADING",
      payload: { loading: false, success: false, error: null },
    });
    try {
      const firestore = getFirestore();
      const orgId = getState().user.currentUser.orgId;

      const departmentRef = await firestore
        .collection("organizations")
        .doc(orgId)
        .collection("departments")
        .orderBy("departmentName", "asc")
        .get();
      let departmentsArray = [];
      if (!departmentRef.empty) {
        for (const doc of departmentRef.docs) {
          const data = doc.data();
          const finalDoc = {
            id: doc.id,
            ...data,
          };
          departmentsArray.push(finalDoc);
        }
      }
      dispatch({
        type: "SET_DEPARTMENTS_DOCS",
        payload: departmentsArray,
      });
      dispatch({
        type: "GET_DEPARTMENT_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_DEPARTMENT_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const deleteOrgDepartment =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    try {
      dispatch({
        type: "DELETE_DEPARTMENT_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const deleteAdminFunction = firebase
        .functions()
        .httpsCallable("deleteAdminFunction");
      const response = await deleteAdminFunction(data);
      if (response.data.success) {
        dispatch({
          type: "DELETE_DEPARTMENT_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "DELETE_DEPARTMENT_LOADING",
          payload: {
            loading: false,
            success: false,
            error: response.data.errorMessage,
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "DELETE_DEPARTMENT_LOADING",
        payload: { loading: false, success: false, error: err.errorMessage },
      });
    }
  };

export const sendDepartmentEmails =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SEND_DEPARTMENT_EMAIL_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const EmailDepartment = firebase
        .functions()
        .httpsCallable("sendEmailToDepartment");
      const result = await EmailDepartment(data);
      if (result.data.success) {
        dispatch({
          type: "SEND_DEPARTMENT_EMAIL_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SEND_DEPARTMENT_EMAIL_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SEND_DEPARTMENT_EMAIL_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const resetAddDepartmentLoading = () => (dispatch) => {
  dispatch({
    type: "ADD_DEPARTMENT_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};

export const resetEmailToDepartmentLoading = () => (dispatch) => {
  dispatch({
    type: "SEND_DEPARTMENT_EMAIL_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};

export const resetDeleteDepartmentLoading = () => (dispatch) => {
  dispatch({
    type: "DELETE_DEPARTMENT_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};
