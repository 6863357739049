import React from "react";
import ReactLoading from "react-loading";
import { Navigate, Route, Routes } from "react-router";
import { getAddressToRedirect, finalRoutes } from "../../utils/layoutFunctions";
import "./react-suspense-component.styles.scss";

const ReactSuspensComponent = ({
  role,
  authenticated,
  redirectPass,
  resetPassword,
  mergeAccount,
  manager,
}) => {
  const finalRoutesResponse = finalRoutes(
    authenticated,
    role,
    resetPassword,
    mergeAccount,
    manager
  );

  const redirectReponse = getAddressToRedirect(
    authenticated,
    role,
    resetPassword,
    mergeAccount,
    manager
  );

  const checkRoleClassName = (role, authenticated) => {
    if (role === "admin" && authenticated) {
      return "logged-in-admin";
    } else if (role === "sys-admin" && authenticated) {
      return "logged-in-sys-admin";
    } else if (role === "manager" && authenticated) {
      return "logged-in-manager";
    } else if (role === "user" && authenticated) {
      return "logged-in-user";
    } else {
      return "";
    }
  };
  console.log("checks", finalRoutesResponse);
  return (
    <div className="suspense-component">
      <React.Suspense
        fallback={
          <ReactLoading
            type={"balls"}
            color={"#ffffff"}
            height={"50px"}
            width={"50px"}
          />
        }
      >
        <div
          className={`app-container ${
            authenticated ? "ml-60" : ""
          } ${checkRoleClassName(role, authenticated)} `}
        >
          <Routes>
            {finalRoutesResponse.map(
              ({ component: Component, ...rest }, idx) => {
                return (
                  Component && (
                    <Route
                      key={idx}
                      path={rest.path}
                      exact={rest.exact}
                      element={Component}
                    />
                  )
                );
              }
            )}
            <Route path="*" element={<Navigate to={redirectReponse} />} />
          </Routes>
        </div>
      </React.Suspense>
    </div>
  );
};

export default ReactSuspensComponent;
