import React from "react";
const Login = React.lazy(() => import("./pages/login-in-page/login-in.page"));
const ErrorPage = React.lazy(() =>
  import("./pages/error-page/error-page.page")
);
const BrokerDashboard = React.lazy(() =>
  import("./pages/dashboard-page/dashboard")
);
const HandleActionUrls = React.lazy(() =>
  import("./pages/handle-action-urls/handle-action-urls.component")
);
const EmailsPage = React.lazy(() => import("./pages/emails/emailPage"));
const ProfilePage = React.lazy(() =>
  import("./pages/profile-page/profile-page")
);

const BrokerIndividualPage = React.lazy(() =>
  import("./pages/broker-Individual-page/broker-Individual-page")
);
const SettingsPage = React.lazy(() =>
  import("./pages/settings-page/setting.page")
);

const CreditApprovalPage = React.lazy(() =>
  import("./pages/Admin-ReqApproval/ReqApprovalPage")
);

const CreditIndividual = React.lazy(() =>
  import("./pages/Admin-CreditApproval-Individual/credit-Individual-page")
);

const JurisdictionRolePage = React.lazy(() =>
  import("./pages/Admin-JuridictionRoles/jurisdiction-roles-page")
);

const BrokersSummaryPage = React.lazy(() =>
  import("./pages/Admin-BrokersSummaryPage/brokersSummary")
);
const GuideLinesPage = React.lazy(() =>
  import("./pages/guidelines-page/guidelines-page")
);

const BrokerGuidelinesPage = React.lazy(() =>
  import("./pages/guidelines-page-user/guidelines-page-user")
);

const MyLicensesPage = React.lazy(() =>
  import("./pages/my-licenses/my-licenses-page")
);

const BulkUploadBrokersPage = React.lazy(() =>
  import("./pages/bulk-upload/bulk-upload-page")
);

const ResetPasswordPage = React.lazy(() =>
  import("./pages/reset-password/resetPassword-page")
);
const FormerEmplyeesPage = React.lazy(() =>
  import("./pages/FormerEmployeesPage/former-employees-page")
);

const SystemAdminDashboardPage = React.lazy(() =>
  import("./pages/sys-dashboard/system-admin-page")
);

const OrganizationPage = React.lazy(() =>
  import("./pages/sys-organization-page/organization-page")
);

const JurisdictionsPage = React.lazy(() =>
  import("./pages/sys-jurisdiction-page/jurisdictions-page")
);

const SYSAddJurisdictionPage = React.lazy(() =>
  import("./pages/sys-addJurisdictionPage/add-jurisdiction-page")
);

const SYSAddOrganizationPage = React.lazy(() =>
  import("./pages/sys-add-organization-page/add-organization-page")
);

const SYSEditOrganizationPage = React.lazy(() =>
  import("./pages/sys-edit-organization/edit-organization-page")
);

const SYSEditJurisdictionPage = React.lazy(() =>
  import("./pages/sys-edit-jurisdiction-page/edit-jurisdiction-page")
);

const MergeAccountPage = React.lazy(() =>
  import("./pages/zensurance-employee-page/zensurance-employee-page")
);

const CESettingsPage = React.lazy(() =>
  import("./pages/sys-ce-settings/sys-ce-settings-page")
);
const SysGuidelinesPage = React.lazy(() =>
  import("./pages/sys-guidelines/sys-guidelines-page")
);

const AdminLicensesPage = React.lazy(() =>
  import("./pages/admin-review-licenses/review-licenses.page")
);

const LicenseDashboardPage = React.lazy(() =>
  import("./pages/admin-license-dashboard/admin-license-dashboard")
);

//mangerPages
const ManagerLicenseDashboard = React.lazy(() =>
  import("./pages/managerSummaryPage/manager-summary-page")
);
const ManagerCEDashboard = React.lazy(() =>
  import("./pages/managerCEDashboardPage/manager-ce-dashboard.page")
);
const ManagerReviewLicenses = React.lazy(() =>
  import("./pages/managerReviewLicensesPage/manager-review-licenses-page")
);
// const ChooseCreditPage = React.lazy(()=> import("./pages/choose-credit-page/choose-credit-page"))
// const CreditBankPage =React.lazy(()=> import("./pages/creditBankPage/credit-bank-page"))
export const loggedOutRoutes = [
  {
    path: "/login",
    name: "Login",
    exact: true,
    component: <Login />,
  },
  {
    path: "/sign_in_link",
    name: "sign in with link",
    component: <HandleActionUrls />,
  },
];

export const brokerRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    component: <BrokerDashboard />,
  },
  {
    path: "/my-licenses",
    name: "MyLicensesPage",
    exact: true,
    component: <MyLicensesPage />,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    exact: true,
    component: <ProfilePage />,
  },
  {
    path: "/broker-guidelines",
    name: "BrokerGuidelinesPage",
    exact: true,
    component: <BrokerGuidelinesPage />,
  },
  {
    path: "/reset-password",
    name: "ResetPasswordPage",
    exact: true,
    component: <ResetPasswordPage />,
  },
];

export const unsetPasswordRoutes = [
  {
    path: "/reset-password",
    name: "ResetPasswordPage",
    exact: true,
    component: <ResetPasswordPage />,
  },
];

export const adminRoutes = [
  {
    path: "/broker/:email/:name",
    name: "BrokerIndividualPage",
    exact: true,
    component: <BrokerIndividualPage />,
  },
  {
    path: "/licenses-dashboard",
    name: "LicenseDashboardPage",
    exact: true,
    component: <LicenseDashboardPage />,
  },
  {
    path: "/credit-approval",
    exact: true,
    name: "CreditApprovalPage",
    component: <CreditApprovalPage />,
  },
  {
    path: "/former-employees",
    exact: true,
    name: "formerEmplyeesPage",
    component: <FormerEmplyeesPage />,
  },
  {
    path: "/emails",
    exact: true,
    name: "emailPage",
    component: <EmailsPage />,
  },
  {
    path: "/credit-approval/:id",
    name: "CreditApprovalIndividual",
    component: <CreditIndividual />,
  },
  {
    path: "/jurisdiction/:id",
    name: "JurisdictionRolePage",
    component: <JurisdictionRolePage />,
  },
  {
    path: "/reset-password",
    name: "ResetPasswordPage",
    exact: true,
    component: <ResetPasswordPage />,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    exact: true,
    component: <ProfilePage />,
  },
  {
    path: "/licenses-approvals",
    name: "ReviewLicensesPage",
    exact: true,
    component: <AdminLicensesPage />,
  },
  {
    path: "/settings",
    exact: true,
    name: "SettingsPage",
    component: <SettingsPage />,
  },
  {
    path: "/guidelines",
    exact: true,
    name: "GuidelinesPage",
    component: <GuideLinesPage />,
  },
  {
    path: "/brokers-summary",
    exact: true,
    name: "BrokersSummaryPage",
    component: <BrokersSummaryPage />,
  },
  {
    path: "/bulk-upload-brokers",
    name: "BulkUploadPage",
    exact: true,
    component: <BulkUploadBrokersPage />,
  },
];

export const undefinedRoutes = [
  {
    path: "/error",
    name: "error",
    exact: true,
    component: <ErrorPage />,
  },
];

export const systemAdminRoutes = [
  {
    path: "/sys-admin-dashboard",
    name: "dashboard",
    exact: true,
    component: <SystemAdminDashboardPage />,
  },
  {
    path: "/reset-password",
    name: "ResetPasswordPage",
    exact: true,
    component: <ResetPasswordPage />,
  },
  {
    path: "/sys-organizations",
    name: "OrganizationPage",
    exact: true,
    component: <OrganizationPage />,
  },
  {
    path: "/sys-jurisdictions",
    name: "Jurisdictions",
    exact: true,
    component: <JurisdictionsPage />,
  },
  {
    path: "/sys-add-jurisdiction",
    name: "AddJurisdictions",
    exact: true,
    component: <SYSAddJurisdictionPage />,
  },
  {
    path: "/sys-add-organization",
    name: "AddOrganization",
    exact: true,
    component: <SYSAddOrganizationPage />,
  },
  {
    path: "/sys-edit-organization/:id",
    name: "EditOrganizationPage",
    component: <SYSEditOrganizationPage />,
  },
  {
    path: "/sys-edit-jurisdiction/:id",
    name: "EditJurisdictionPage",
    component: <SYSEditJurisdictionPage />,
  },
  {
    path: "/sys-ce-settings/:id",
    name: "CESettingsPage",
    component: <CESettingsPage />,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    exact: true,
    component: <ProfilePage />,
  },
  {
    path: "/sys-guidelines",
    name: "GuidelinesPage",
    component: <SysGuidelinesPage />,
  },
];

export const mergeAccountRoutes = [
  {
    path: "/merge",
    name: "MergeAccount",
    exact: true,
    component: <MergeAccountPage />,
  },
];

export const managerRoutes = [
  {
    path: "/manager-license-dashboard",
    name: "ManagerLicenseDashboard",
    exact: true,
    component: <ManagerLicenseDashboard />,
  },
  {
    path: "/manager-ce-dashboard",
    name: "ManagerCEDashboard",
    exact: true,
    component: <ManagerCEDashboard />,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    exact: true,
    component: <ProfilePage />,
  },
  {
    path: "/broker-guidelines",
    name: "BrokerGuidelinesPage",
    exact: true,
    component: <BrokerGuidelinesPage />,
  },
  {
    path: "/manager-review-licenses",
    name: "ManagerReviewLicensesPage",
    exact: true,
    component: <ManagerReviewLicenses />,
  },
  {
    path: "/reset-password",
    name: "ResetPasswordPage",
    exact: true,
    component: <ResetPasswordPage />,
  },
];
